import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { catchAndLogError } from './sendErrorLog';
import { MAX_COMPLETIONS_PER_SEARCH, RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

export const getDocumentSuggestions = async (prefix: string) => {
  if (prefix.trim().length === 0) {
    return { completions: [] };
  }

  const token = await getAuthToken();
  const response = await axios.post<{
    completions: { legal_id: string; title: string; document_id: string }[];
  }>(
    `${RESOURCE_API_URL}/database/completion/legal`,
    {
      prefix,
      limit: MAX_COMPLETIONS_PER_SEARCH,
    },
    {
      responseType: 'json',
      ...standardAxiosOptions(token),
    },
  );
  return response.data;
};

export const useDocumentSuggestions = (prefix: string, enabled: boolean) => {
  return useQuery({
    queryKey: ['document_suggestions', prefix],
    queryFn: () => catchAndLogError(() => getDocumentSuggestions(prefix)),
    placeholderData: keepPreviousData,
    enabled,
  });
};
