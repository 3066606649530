import { usePostHog } from 'posthog-js/react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { page_change } from './events';

/**
 * emit an event on location change tracking the maximum scroll depth
 */
export const useSearchDepth = () => {
  const posthog = usePostHog();
  const maxPercentage = useRef(0);
  const maxPixels = useRef(0);
  const location = useLocation();

  useEffect(() => {
    function handleScroll() {
      const lastPercentage = Math.min(
        1,
        (window.innerHeight + window.pageYOffset) / document.body.offsetHeight,
      );
      const lastPixels = window.innerHeight + window.pageYOffset;
      if (lastPercentage > maxPercentage.current) {
        maxPercentage.current = lastPercentage;
      }
      if (lastPixels > maxPixels.current) {
        maxPixels.current = lastPixels;
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      posthog.capture(page_change, {
        max_scroll_pc: maxPercentage.current,
        max_scroll_px: maxPixels.current,
        last_scroll_pc: Math.min(
          1,
          (window.innerHeight + window.pageYOffset) / document.body.offsetHeight,
        ),
        last_scroll_px: window.innerHeight + window.pageYOffset,
        scrolled: maxPixels.current > 0,
      });
    };

    handleRouteChange();
  }, [location, posthog]);
};
