import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';
import { ExamplePrompts } from './ExamplePrompts';
import Feedback from '../../components/Feedback/Feedback';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import UserDropDown from '../../components/UserDropDown/UserDropDown';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { sendQuery } from '../../state/actions/sendQuery';
import { isSearchActive } from '../../state/hooks/useIsSearchActive';
import { setSearchError } from '../../state/actions/searchError';
import { Icons } from '@/assets';
import { search_start } from '@/services/posthog/events';
import { sendErrorLog } from '@/services/api/actions/sendErrorLog';
import { catchAppError } from '@/utils/createAppError';

export const SearchPromptPage = () => {
  const [searchId, setSearchId] = useState<string | null>(null);
  const navigate = useNavigate();
  const postHog = usePostHog();

  useEffect(() => {
    createNewSearch()
      .then(({ searchId }) => {
        setSearchId(searchId);
      })
      .catch(() => {});
  }, []);

  const onSearch = async () => {
    try {
      let currentSearchId = searchId;

      // if the search was not yet created or if the search was cancelled due to inactivity
      // we create a new search
      if (!currentSearchId || !isSearchActive(currentSearchId)) {
        const { searchId } = await createNewSearch();
        currentSearchId = searchId;
      }

      postHog.capture(search_start);
      sendQuery(currentSearchId);
      navigate(`/search/${currentSearchId}`);
    } catch (err) {
      sendErrorLog(err);
      setSearchError(catchAppError(err));
    }
  };

  const selectDocument = async (documentId: string) => {
    navigate(`/document/${documentId}`);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen w-screen gap-8 ">
        <Icons.LogoText className="h-5" />
        <div className="flex flex-col w-3/5">
          <SearchBar onSearch={onSearch} disabled={false} selectDocument={selectDocument} />
          <div className="px-4">
            <ExamplePrompts onSearch={onSearch} />
          </div>
        </div>
      </div>
      <div className="fixed right-10 top-10 z-30">
        <UserDropDown />
      </div>
      <Feedback />
      <ToastContainer className="pointer-events-auto" />
    </>
  );
};
