import posthog from 'posthog-js';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBarDropdownOption from './SearchBarDropdownOption';
import { setParams, setQuery } from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/useSearchStore';
import { Icons } from '@/assets';
import ErrorMessage from '@/common/ErrorMessage/ErrorMessage';
import { useCompletionSuggestions } from '@/services/api/actions/getCompletionSuggestions';
import { useDocumentSuggestions } from '@/services/api/actions/getDocumentSuggestions';
import { useRecentSearches } from '@/services/firebase/functions/getRecentSearches';
import {
  search_completion_suggestion_select,
  search_document_suggestion_select,
  search_history_select,
} from '@/services/posthog/events';
import { SearchParams } from '@/types/api';

type Props = {
  initialQuery?: string;
  onSearch: (query: string) => void;
  selectDocument: (documentId: string) => void;
  disabled?: boolean;
};

type DropDownOption =
  | {
      type: 'recent';
      search: SearchParams;
    }
  | {
      type: 'document';
      title: string;
      legalId: string;
      documentId: string;
    }
  | {
      type: 'completion';
      suggestion: string;
    };

const LINE_HEIGHT = 23;
const MAX_HEIGHT = 23 * 6;

export const SearchBar = (props: React.PropsWithChildren<Props>) => {
  const { onSearch, disabled, selectDocument } = props;
  const { t } = useTranslation();
  const [textareaHeight, setTextareaHeight] = useState(LINE_HEIGHT);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const query = useSearchStore((state) => state.currentParams.query);
  const [isFocused, setIsFocused] = useState(false);
  const error = useSearchStore((state) => state.error);

  const { data: documentSuggestions } = useDocumentSuggestions(query, isFocused);
  const { data: completionSuggestions } = useCompletionSuggestions(query, isFocused);
  const { data: recentSearches } = useRecentSearches(5);

  const dropdownOptions = useMemo<DropDownOption[]>(() => {
    if (!isFocused) return [];
    const options: DropDownOption[] = [];

    if (recentSearches && query.trim().length == 0) {
      options.push(...recentSearches.map((search) => ({ type: 'recent' as const, search })));
      return options;
    }

    if (documentSuggestions?.completions) {
      options.push(
        ...documentSuggestions.completions.map(({ title, legal_id, document_id }) => ({
          type: 'document' as const,
          title,
          legalId: legal_id,
          documentId: document_id,
        })),
      );
    }

    if (completionSuggestions?.completions) {
      options.push(
        ...completionSuggestions.completions.map(({ suggestion }) => ({
          type: 'completion' as const,
          suggestion,
        })),
      );
    }

    return options;
  }, [recentSearches, documentSuggestions, completionSuggestions, isFocused, query]);

  useEffect(() => {
    updateTextareaHeight();
  }, [query]);

  const updateTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
      setTextareaHeight(heightShouldBe);
    }
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
    setSelectedOptionIndex(null);
    setTextareaHeight(textareaRef.current?.clientHeight || 0);
    updateTextareaHeight();
  };

  const selectOption = (option: DropDownOption) => {
    switch (option.type) {
      case 'recent':
        posthog.capture(search_history_select);
        setParams(option.search);
        onSearch(option.search.query);
        break;
      case 'document':
        posthog.capture(search_document_suggestion_select);
        selectDocument(option.documentId);
        break;
      case 'completion':
        posthog.capture(search_completion_suggestion_select);
        setQuery(option.suggestion);
        onSearch(option.suggestion);
        break;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.shiftKey) {
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        setSelectedOptionIndex((selected) =>
          selected == null ? 0 : selected == dropdownOptions.length - 1 ? null : selected + 1,
        );
        e.preventDefault();
        break;
      case 'ArrowUp':
        setSelectedOptionIndex((selected) =>
          selected == null ? dropdownOptions.length - 1 : selected == 0 ? null : selected - 1,
        );
        e.preventDefault();
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedOptionIndex !== null) {
          selectOption(dropdownOptions[selectedOptionIndex]);
        } else if (query.trim().length > 0) {
          onSearch(query);
          textareaRef.current?.blur();
        }
        break;
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setTimeout(() => setIsFocused(false), 500);
    setSelectedOptionIndex(null);
  };

  const onSearchClick = () => {
    if (query.trim().length > 0) {
      onSearch(query);
    }
  };

  return (
    <div className="py-4 relative">
      <div style={{ height: textareaHeight }} />

      <div
        className={`absolute top-0 flex flex-col w-full px-5 py-4 rounded-[28px] text-qura-neutral-jet bg-white text-sm shadow-qura z-20 border-1 border-gray-100 `}>
        <div className={`flex relative gap-[10px] items-center border-green-500`}>
          <button onClick={onSearchClick} title="Search">
            <Icons.NewSearch className="relative" />
          </button>

          <textarea
            ref={textareaRef}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ lineHeight: `${LINE_HEIGHT}px` }}
            className="flex-1 resize-none bg-transparent outline-none border-none"
            value={query}
            onChange={onChangeTextarea}
            onKeyDown={handleKeyDown}
            placeholder={t('searchBar.placeholder')}
          />
          {error && (
            <div className="flex items-center absolute top-0 bottom-0 right-0 pb-0.5">
              <ErrorMessage
                error={error}
                className="text-center text-qura-red/80 font-semibold px-4 py-1 tracking-wide text-[15px] bg-qura-neutral-ghost rounded-full"
              />
            </div>
          )}
        </div>

        {dropdownOptions.length > 0 && <div className="h-[1px] w-full bg-gray-200 mt-3 mb-1" />}

        {dropdownOptions.some((o) => o.type == 'recent') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.searchHistory')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'recent' && (
                  <SearchBarDropdownOption
                    key={option.search.query + index}
                    selected={selectedOptionIndex === index}
                    title={option.search.query}
                    onClick={() => selectOption(option)}
                    titleIcon="Clock"
                  />
                ),
            )}
          </div>
        )}

        {dropdownOptions.some((o) => o.type == 'document') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.documentMatch')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'document' && (
                  <SearchBarDropdownOption
                    key={option.legalId + index}
                    selected={selectedOptionIndex === index}
                    title={option.title}
                    tag={option.legalId}
                    tagIcon={'Document'}
                    onClick={() => selectOption(option)}
                  />
                ),
            )}
          </div>
        )}

        {dropdownOptions.some((o) => o.type == 'completion') && (
          <div>
            <p className="text-tiny mt-2 text-qura-neutral-balanced">
              {t('searchBar.suggestions')}
            </p>
            {dropdownOptions.map(
              (option, index) =>
                option.type == 'completion' && (
                  <SearchBarDropdownOption
                    key={option.suggestion + index}
                    selected={selectedOptionIndex === index}
                    title={option.suggestion}
                    titleIcon={'ArrowTiltedUpRight'}
                    onClick={() => selectOption(option)}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
