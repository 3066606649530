import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { sendErrorLog } from '@/services/api/actions/sendErrorLog';
import { getUserData } from '@/services/firebase/functions/getUserData';
import i18n from '@/services/i18next/i18nextConfig';

export interface UserState {
  id: string;
  name: string;
  company: {
    id: string;
    name: string;
  };
  email: string | null;
  language: string;
  code: string | null;
  emailVerified: boolean;
}

export interface AuthContextType {
  user: UserState | null;
  isInitialized: boolean;
}

const MIN_LOADING_TIME_MS = 340;

export const AuthContext = createContext<AuthContextType>({ user: null, isInitialized: false });

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useState<UserState | null>(null);
  const [isInitialized, setInitialized] = useState(false);
  const auth = getAuth();
  const updateUser = async (user: User | null) => {
    const startTime = Date.now();
    if (user) {
      const { company, userData } = await getUserData(user.uid);
      setUser({
        id: user.uid,
        name: userData.username,
        company,
        email: user.email,
        language: userData.language,
        code: userData.code ?? '',
        emailVerified: userData.emailVerified,
      });

      i18n.changeLanguage(userData.language);
    } else {
      setUser(null);
    }
    // we want to show the loading screen for a bit longer to prevent flickering
    const elapsedTime = Date.now() - startTime;
    const remainingTime = MIN_LOADING_TIME_MS - elapsedTime;

    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      updateUser(user)
        .then(() => setInitialized(true))
        .catch((error) => {
          sendErrorLog(error);
          setInitialized(true);
          console.error(error);
        });
    });

    return () => unsubscribe();
  }, [auth]);

  return <AuthContext.Provider value={{ user, isInitialized }}>{children}</AuthContext.Provider>;
};
