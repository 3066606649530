import { ReactNode, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideColumn } from './SideColumn';
import { TopBar } from './TopBar';
import Feedback from '../Feedback/Feedback';

const TOP_BAR_GAP = 24;

const Layout = ({
  query,
  onSearch,
  selectDocument,
  children,
  leftContent,
  rightContent,
  showBackButtonLink,
  contentClassName,
  originalSearchId,
}: {
  onSearch: () => Promise<void>;
  selectDocument: (documentId: string) => void;
  query?: string;
  children: ReactNode;
  leftContent?: () => ReactNode;
  rightContent?: () => ReactNode;
  showBackButtonLink?: string;
  originalSearchId?: string;
  contentClassName?: string;
}) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLHeadElement>(null);

  const [leftWidth, setLeftWidth] = useState<number>(0);
  const [rightWidth, setRightWidth] = useState<number>(0);
  const [topBarHeight, setTopBarHeight] = useState<number>(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (topBarRef.current) setTopBarHeight(topBarRef.current.clientHeight + TOP_BAR_GAP);
      if (leftRef.current) setLeftWidth(leftRef.current.clientWidth);
      if (rightRef.current) setRightWidth(rightRef.current.clientWidth);
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    window.addEventListener('resize', updateDimensions);

    if (topBarRef.current) resizeObserver.observe(topBarRef.current);
    if (leftRef.current) resizeObserver.observe(leftRef.current);
    if (rightRef.current) resizeObserver.observe(rightRef.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <main id="main" className="flex flex-col items-center" style={{ paddingTop: topBarHeight }}>
      <TopBar
        initialQuery={query}
        onSearch={onSearch}
        ref={topBarRef}
        showBackButtonLink={showBackButtonLink}
        originalSearchId={originalSearchId}
        selectDocument={selectDocument}
      />
      <div
        className="flex w-screen"
        style={{
          height: `calc(100vh - ${topBarHeight}px - 1rem)`,
        }}>
        <div className="flex flex-1" ref={leftRef} />
        <div className={`${contentClassName}`}>{children}</div>
        <div className="flex flex-1" ref={rightRef} />
      </div>

      <div
        className="fixed left-0 bottom-4 "
        style={{
          top: topBarHeight,
          width: leftWidth - 8,
        }}>
        <SideColumn side="left">{leftContent && leftContent()}</SideColumn>
      </div>

      <div
        className="fixed right-0 bottom-4 "
        style={{
          top: topBarHeight,
          width: rightWidth - 8,
        }}>
        <SideColumn side="right">{rightContent && rightContent()}</SideColumn>
      </div>

      <Feedback />
      <ToastContainer className="pointer-events-auto" />
    </main>
  );
};

export default Layout;
