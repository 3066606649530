import axios from 'axios';
import { getAuthToken } from '../../firebase/functions/getCurrentUser';
import { RESOURCE_API_URL } from '../constants';
import { pruneBigData } from '../utils';

export async function sendFeedback(message: string, data: Record<string, unknown>): Promise<void> {
  const token = await getAuthToken();
  if (!token) return;
  await axios.post(`${RESOURCE_API_URL}/log/feedback`, {
    message,
    data: pruneBigData(data, ['pdf']),
  });
}
