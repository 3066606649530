import * as Sentry from '@sentry/react';

Sentry.init({
  environment: import.meta.env.VITE_NODE_ENV,
  dsn: 'https://d455650e316b0e91d885dce5ef6186f2@o4507768773869568.ingest.de.sentry.io/4507768899436625',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
