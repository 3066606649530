import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { clearParams } from '../../state/actions/searchParams';
import { SearchBar } from '../SearchBar/SearchBar';
import UserDropDown from '../UserDropDown/UserDropDown';
import { Icons } from '@/assets';

type TopBarProps = {
  initialQuery?: string;
  onSearch: (query: string) => Promise<void>;
  selectDocument: (documentId: string) => void;
  showBackButtonLink?: string;
  originalSearchId?: string;
};

export const TopBar = forwardRef<HTMLHeadElement, TopBarProps>(
  ({ initialQuery, onSearch, showBackButtonLink, selectDocument }, ref) => {
    const { t } = useTranslation();
    return (
      <header ref={ref} className="fixed top-0 z-10">
        <div className="flex items-end pt-6 bg-qura-bg">
          <div className="flex w-screen px-10 items-center gap-7 relative">
            <div className="flex flex-col">
              <Link to="/" onClick={() => clearParams()}>
                <Icons.LogoText className=" w-20" />
              </Link>
              {showBackButtonLink && (
                <div className="self-start h-0">
                  <Link
                    className="absolute flex mt-1 text-black gap-1 items-center"
                    to={showBackButtonLink}
                    relative="path"
                    onClick={() => {}}>
                    <Icons.Arrow className="w-3 h-3 rotate-90 text-gray-600" />
                    <span className="text-sm text-gray-600">{t('layout.allResults')}</span>
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <SearchBar
                initialQuery={initialQuery}
                onSearch={onSearch}
                selectDocument={selectDocument}
                disabled={false}
              />
            </div>
            <UserDropDown />
          </div>
        </div>
      </header>
    );
  },
);
