import { useSearchStore } from '../useSearchStore';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';

export const sendQuery = (searchId: string) => {
  const ws = useSearchStore.getState().searches[searchId]?.ws;

  if (!ws) {
    throw new Error('WebSocket not initialized');
  }

  const currentParams = useSearchStore.getState().currentParams;
  const payload = convertBackToSnakeCaseRecursive({
    content_type: 'search',
    content: currentParams,
  });

  ws.send(JSON.stringify(payload));
};
