// global
export const app_error = 'app_error';
export const feedback_sent = 'feedback_sent';
export const page_change = 'page_change';

// home page
export const search_start = 'search_start';
export const search_complete = 'search_complete';
export const search_example_select = 'search_example_select';
export const search_history_select = 'search_history_select';
export const search_document_suggestion_select = 'search_document_suggestion_select';
export const search_completion_suggestion_select = 'search_completion_suggestion_select';

// search result page
export const filter_tag_select = 'filter_tag_select';
export const filter_tag_deselect = 'filter_tag_deselect';
export const filter_reset = 'filter_reset';
export const filter_apply = 'filter_apply';
export const filter_start_date_select = 'filter_start_date_select';
export const filter_end_date_select = 'filter_end_date_select';
export const search_result_expand = 'search_result_expand';
export const search_result_open = 'search_result_open';
export const search_try_again = 'search_try_again';
export const navigate_back_home = 'navigate_back_home';

// document result page
export const download_pdf_with_highlighting = 'download_pdf_with_highlighting';
export const download_pdf_without_highlighting = 'download_pdf_without_highlighting';
export const document_snippet_click = 'document_snippet_click';
export const navigate_back_to_search_result = 'navigate_back_to_search_result';
