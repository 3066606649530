import { AxiosRequestConfig } from 'axios';
import { RESOURCE_API_URL } from './constants';

export const getDocumentFileUrl = (documentId: string, searchId?: string): string => {
  return searchId
    ? `${RESOURCE_API_URL}/search/${searchId}/document/${documentId}`
    : `${RESOURCE_API_URL}/database/document/${documentId}`;
};

export const pruneBigData = (obj: any, fieldsToPrune: string[]) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const newObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (fieldsToPrune.includes(key)) {
        continue;
      }
      newObj[key] = pruneBigData(obj[key], fieldsToPrune);
    }
  }

  return newObj;
};

export const standardAxiosOptions = (token: string): AxiosRequestConfig => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'x-forwarded-authorization': `Bearer ${token}`,
  };

  return {
    timeout: 40000,
    headers,
  };
};
