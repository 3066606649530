import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { catchAndLogError } from './sendErrorLog';
import { availableFiltersSchema } from '../apiValidation';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

export async function getFilterTagsRequest() {
  const token = await getAuthToken();
  const response = await axios.get(
    `${RESOURCE_API_URL}/database/filters/tags`,
    standardAxiosOptions(token),
  );
  return availableFiltersSchema.parse(response.data);
}

export const useFilterTags = () => {
  return useQuery({
    queryKey: ['filter_tags'],
    queryFn: () => catchAndLogError(() => getFilterTagsRequest()),
  });
};
