import { getAuth } from 'firebase/auth';
import { getBrowserId, getSessionId } from './getSessionId';
import { useSearchStore } from '@/features/Search/state/useSearchStore';

export function getLogMetadata(): any {
  const localStorageData: Record<string, string | null> = {};
  const cookieData = document.cookie;

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) continue;
    localStorageData[key] = localStorage.getItem(key);
  }

  const authUser = getAuth().currentUser;
  const metadata = {
    browser_id: getBrowserId(),
    session_id: getSessionId(),
    name: authUser?.displayName,
    email: authUser?.email,
    uid: authUser?.uid,
    location: window.location,
    searchStore: useSearchStore.getState(),
    localStorage: localStorageData,
    cookies: cookieData,
  };

  return metadata;
}
