import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useCurrentUser } from '../firebase/context/useCurrentUser';

export const useSentryTags = () => {
  const { user } = useCurrentUser();

  useEffect(() => {
    Sentry.setTag('user_uid', user?.id);
    Sentry.setTag('user_email', user?.email);
    Sentry.setTag('user_name', user?.name);
    Sentry.setTag('user_company_id', user?.company.id);
    Sentry.setTag('user_company_name', user?.company.name);
    Sentry.setTag('user_language', user?.language);
  }, [user]);
};
