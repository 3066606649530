import { usePostHog } from 'posthog-js/react';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FilterBadge } from './FilterBadge';
import { FilterHeader } from './FilterHeader';
import TagButton from './TagButton';
import { YearFilter } from './YearFilter';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { resetFilter } from '../../state/actions/searchParams';
import { setSearchError } from '../../state/actions/searchError';
import { sendQuery } from '../../state/actions/sendQuery';
import { useFilterHasChanged } from '../../state/hooks/useFilterHasChanged';
import { AvailableFilters, TagNode } from '@/types/api';
import { sendErrorLog } from '@/services/api/actions/sendErrorLog';
import { catchAppError } from '@/utils/createAppError';
import { filter_apply, filter_reset } from '@/services/posthog/events';

const renderTag = (option: TagNode, parentTag: TagNode | null) => {
  return (
    <TagButton key={option.tagId} tag={option} parentTag={parentTag} renderSubTag={renderTag} />
  );
};

const FilterDivider = () => {
  return <div className={'h-[1px] flex-shrink-0 bg-qura-neutral-silver mx-5 '} />;
};

const FilterTags = ({ tagsFilter }: { tagsFilter: AvailableFilters }) => {
  const sortedTagNodes = useMemo(
    () => tagsFilter.tagNodes.sort((a, b) => a.weight - b.weight),
    [tagsFilter.tagNodes],
  );

  return (
    <div className="flex flex-col flex-shrink overflow-y-auto items-stretch w-full px-5 gap-1.5 py-2">
      {sortedTagNodes.map((tag) => renderTag(tag, null))}
    </div>
  );
};

interface ApplyButtonProps {
  searchId: string;
  onApplyFilters: () => void;
}

const ApplyButton = (props: ApplyButtonProps) => {
  const { onApplyFilters, searchId } = props;
  const hasChanged = useFilterHasChanged(searchId);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mx-5 text-xs">
      <button
        disabled={!hasChanged}
        className={` border border-qura-neutral-silver rounded-md mt-0.5 py-1.5 text-center ${
          !hasChanged
            ? ' bg-qura-neutral-ghost text-qura-neutral-light cursor-not-allowed'
            : 'bg-qura-neutral-jet text-white hover:bg-qura-neutral-balanced font-medium'
        }`}
        onClick={onApplyFilters}>
        {t('filters.applyFilters')}
      </button>
    </div>
  );
};

export const Filtering: FC<{
  className?: string;
  searchId: string;
  tagsFilter: AvailableFilters;
}> = ({ searchId, tagsFilter }) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const makeNewSearch = async () => {
    try {
      const { searchId } = await createNewSearch();
      posthog.capture(filter_apply);
      sendQuery(searchId);
      navigate(`/search/${searchId}`);
    } catch (e) {
      sendErrorLog(e);
      setSearchError(catchAppError(e));
    }
  };

  const onResetClick = () => {
    posthog.capture(filter_reset);
    resetFilter();
    makeNewSearch();
  };

  return (
    <>
      <div className="w-filter flex flex-col flex-shrink bg-white py-3 overflow-hidden rounded shadow-qura ">
        <FilterHeader onResetClick={onResetClick} />
        <FilterDivider />
        <FilterTags tagsFilter={tagsFilter} />
        <FilterDivider />
        <div className="h-2" />
        <YearFilter />
        <div className="h-2" />
        <FilterDivider />
        <div className="h-2" />
        <ApplyButton searchId={searchId} onApplyFilters={makeNewSearch} />
      </div>
      <FilterBadge />
    </>
  );
};
