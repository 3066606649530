import { useQuery } from '@tanstack/react-query';
import { signInWithToken } from '@/services/firebase/functions/signInWithToken';
import { verifyEmail } from '@/services/firebase/functions/verifyEmail';
import { createAppError } from '@/utils/createAppError';
import { catchAndLogError } from '@/services/api/actions/sendErrorLog';

export const useEmailVerify = (email: string | null, confirmationCode: string | null) => {
  const emailVerifyQuery = useQuery({
    queryKey: ['email_verify'],
    queryFn: () =>
      catchAndLogError(async () => {
        if (!email || !confirmationCode) return null;
        return await verifyEmail(email, confirmationCode);
      }),
    enabled: !!email && !!confirmationCode,
  });

  const signInWithTokenQuery = useQuery({
    queryKey: ['sign_in_with_token'],
    queryFn: () =>
      catchAndLogError(async () => {
        if (!emailVerifyQuery.data?.isValid || !emailVerifyQuery.data?.idToken) return null;
        await signInWithToken(emailVerifyQuery.data.idToken);
        return null;
      }),
    enabled:
      !!emailVerifyQuery.data?.isValid &&
      !!emailVerifyQuery.data?.idToken &&
      !!email &&
      !!confirmationCode,
  });

  const isSuccess = !!(emailVerifyQuery.isSuccess && signInWithTokenQuery.isSuccess);
  const isError = emailVerifyQuery.isError || emailVerifyQuery.isError;
  const isLoading = emailVerifyQuery.isLoading || signInWithTokenQuery.isLoading;
  const errorMessage = emailVerifyQuery.error?.message || signInWithTokenQuery.error?.message || '';
  const error = isError ? createAppError('email-verify-failed', errorMessage) : null;

  return {
    isSuccess: isSuccess,
    isLoading: isLoading,
    isError: isError,
    error: error,
  };
};
