import { httpsCallable } from 'firebase/functions';
import { z } from 'zod';
import { functions } from '../firebaseConfig';
import { createAppError } from '@/utils/createAppError';

const _verifyEmail = httpsCallable(functions, 'verifyEmail');

export const VerifyEmailResponseSchema = z.object({
  isValid: z.boolean(),
  idToken: z.string(),
});

export type VerifyEmailResponse = z.infer<typeof VerifyEmailResponseSchema>;

export async function verifyEmail(email: string, verificationToken: string) {
  try {
    const result = await _verifyEmail({ email, verificationToken });
    const response = VerifyEmailResponseSchema.parse(result.data);
    return response;
  } catch (error: any) {
    const message = error.message;
    throw createAppError(
      'unknown',
      'Failed to verify email with Firebase. Firebase Error Message: ' + message,
    );
  }
}
