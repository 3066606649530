import { useSearchStore } from '../useSearchStore';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';

export const setSearchError = (error: AppError | null) => {
  useSearchStore.setState(
    (state) => {
      state.error = error;
    },
    false,
    'searches/add-filter-tag',
  );
};
