import { getScoreHue } from '@/utils/getScoreHue';

type MatchScoreProps = {
  score: number | null;
  className?: string;
};

export const MatchScore = ({ score, className }: MatchScoreProps) => {
  return (
    <p
      className={`${className ?? ''} bg-qura-neutral-ghost`}
      style={{
        backgroundColor: score !== null ? `hsl(${getScoreHue(score)}, 100%, 91%)` : undefined,
      }}>
      {score === null ? '-' : Math.floor(score * 100) + '%'}
    </p>
  );
};
