import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { z } from 'zod';

const userSchema = z.object({
  username: z.string(),
  companyId: z.string(),
  language: z.enum(['en', 'sv']),
  code: z.string().nullable().default(null),
  emailVerified: z.boolean(),
});

const companySchema = z.object({
  companyName: z.string(),
});

export async function getUserData(uid: string) {
  const userdocRef = doc(db, 'users', uid);
  const userdocSnapshot = await getDoc(userdocRef);

  const userData = userSchema.parse(userdocSnapshot.data());

  const companydocRef = doc(db, 'companies', userData.companyId);
  const companydocSnapshot = await getDoc(companydocRef);
  const companyData = companySchema.parse(companydocSnapshot.data());

  const company = {
    id: userData.companyId,
    name: companyData.companyName,
  };

  return { userData, company };
}
