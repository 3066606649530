import { useSearchStore } from '../useSearchStore';

export const useFilterBeenUsed = () => {
  const used = useSearchStore((state) => {
    const { filter } = state.currentParams;
    return (
      filter.tagIds.length > 0 ||
      filter.documentIds.length > 0 ||
      filter.afterDate !== null ||
      filter.beforeDate !== null
    );
  });

  return used;
};
