import Arrow from './icons/arrow-icon.svg?react';
import ArrowLarge from './icons/arrow-large.svg?react';
import ArrowTiltedUpRight from './icons/arrow-tilted-up-right.svg?react';
import Book from './icons/book-icon.svg?react';
import Calendar from './icons/calendar-icon.svg?react';
import Chat from './icons/chat-icon.svg?react';
import CheckMark from './icons/checkmark.svg?react';
import Close from './icons/close-icon.svg?react';
import Lock from './icons/lock-icon.svg?react';
import Logo from './icons/logo-icon.svg?react';
import LogoText from './icons/logo-text.svg?react';
import Mail from './icons/mail-icon.svg?react';
import NewSearch from './icons/new-search-icon.svg?react';
import QuestionMark from './icons/question-mark-icon.svg?react';
import Search from './icons/search-icon.svg?react';
import User from './icons/user-icon.svg?react';
import Document from './icons/document-icon.svg?react';
import UserPicture from './icons/user-icon2.svg?react';
import Clock from './icons/clock-icon.svg?react';
import Checked from './icons/checked-icon.svg?react';
import OpenedEye from './icons/opened-eye-icon.svg?react';
import ClosedEye from './icons/closed-eye-icon.svg?react';
import Info from './icons/info-icon.svg?react';

import loginBlob from './images/login-blob.png';

export const Icons = {
  Logo,
  LogoText,
  Search,
  Chat,
  Arrow,
  Book,
  QuestionMark,
  Close,
  Mail,
  User,
  ArrowLarge,
  Calendar,
  ArrowTiltedUpRight,
  NewSearch,
  UserPicture,
  CheckMark,
  Lock,
  Document,
  Clock,
  Checked,
  OpenedEye,
  ClosedEye,
  Info,
};

export const images = {
  loginBlob,
};
