import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthError } from '../../components/AuthError';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import AuthTextInput from '../../components/AuthTextInput';
import { createAppError } from '@/utils/createAppError';
import { signupUser } from '@/services/firebase/functions/signupWIthToken';
import { sendConfirmationEmail } from '@/services/firebase/functions/sendConfirmationEmail';
import { useCompanyFromSignupToken } from '@/services/firebase/functions/getCompanyFromSignupToken';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { Icons } from '@/assets';
import { sendErrorLog } from '@/services/api/actions/sendErrorLog';
import AuthPasswordInput from '@/features/Auth/components/AuthPasswordInput.tsx';

const errorSchema = z.object({
  details: z.object({
    code: z.enum([
      'invalid-argument',
      'expired-token',
      'invalid-token',
      'invalid-email',
      'invalid-password',
      'invalid-username',
      'invalid-credentials',
      'invalid-email-domain',
      'email-already-in-use',
      'internal-error',
    ]),
  }),
});

type ErrorCode = z.infer<typeof errorSchema>['details']['code'];
const errorCode: Record<ErrorCode, string> = {
  'invalid-token': 'errors.invalidSignupToken',
  'expired-token': 'errors.invalidSignupToken',
  'invalid-email': 'errors.invalidEmail',
  'invalid-password': 'errors.invalidPassword',
  'invalid-username': 'errors.invalidUsername',
  'invalid-credentials': 'errors.invalidCredentials',
  'invalid-email-domain': 'errors.invalidEmail',
  'email-already-in-use': 'errors.emailAlreadyUsed',
  'internal-error': 'errors.unknown',
  'invalid-argument': 'errors.unknown',
};

export const SignupPage = () => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [outlineName, setOutlineName] = useState<boolean>(false);
  const [outlineEmail, setOutlineEmail] = useState<boolean>(false);
  const [outlinePassword, setOutlinePassword] = useState<boolean>(false);

  const [signupLoading, setSignupLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<AppError | null>(null);

  const signupToken = searchParams.get('token');
  const { data: companyName } = useCompanyFromSignupToken(signupToken);

  useEffect(() => {
    if (!signupToken) {
      setError(createAppError('invalid-signup-token', 'No signup token found in URL.'));
    }
  }, [signupToken, navigate]);

  const loginClick = () => {
    navigate('/auth');
  };

  const signupClick = () => {
    if (!signupToken) return;

    const errors: string[] = [];

    if (name.length === 0) {
      errors.push('Name is required.');
      setOutlineName(true);
    } else if (name.length <= 2) {
      errors.push('Name must be at least three characters long.');
      setOutlineName(true);
    } else {
      setOutlineName(false);
    }

    if (email.length === 0) {
      errors.push('Email is required.');
      setOutlineEmail(true);
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.push('Invalid email format.');
      setOutlineEmail(true);
    } else {
      setOutlineEmail(false);
    }

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
      setOutlinePassword(true);
    } else if (password !== confirmPassword) {
      errors.push('Passwords do not match.');
      setOutlinePassword(true);
    } else {
      setOutlinePassword(false);
    }

    if (errors.length > 0) {
      setError(createAppError('message', errors.join('\n')));
    } else {
      setSignupLoading(true);
      setError(null);
      signupUser(signupToken, email, password, name)
        .then(() => {
          sendConfirmationEmail(email).catch((err) => {
            sendErrorLog(err);
          });
          navigate(`/auth/confirm-email?email=${email}`);
          setSignupLoading(false);
        })
        .catch((error) => {
          sendErrorLog(error);
          setSignupLoading(false);
          const { data: parsedError } = errorSchema.safeParse(error);
          if (!parsedError) {
            setError(createAppError('unknown', 'Unable to parse signup error'));
            return;
          }
          const { code } = parsedError.details;
          setError(createAppError('message', t(errorCode[code])));
        });
    }
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        {companyName ? (
          <div className="flex flex-col items-center justify-center mt-8 mb-6">
            <p className="rounded-[39px] text-qura-neutral-jet text-xs text-center bg-qura-neutral-ghost px-2 py-1 ">
              {companyName}
            </p>
          </div>
        ) : (
          <div className="h-12" />
        )}
        <AuthLogo />
        <AuthInputCard>
          <AuthDescription text={t('signupPage.description')} />
          <AuthTextInput
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="name"
            value={name || ''}
            placeholder={t('signupPage.namePlaceholder')}
            Icon={Icons.User}
            label={t('common.name')}
            isError={outlineName}
          />
          <div className="h-5" />
          <AuthTextInput
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            value={email}
            placeholder={t('common.emailPlaceholder')}
            Icon={Icons.Mail}
            label={t('common.email')}
            isError={outlineEmail}
          />
          <div className="h-5" />
          <AuthPasswordInput
            label={t('common.password')}
            placeholder={t('common.passwordPlaceholder')}
            id="password"
            value={password}
            isError={outlinePassword}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="h-5" />
          <AuthPasswordInput
            label={t('common.confirmPassword')}
            placeholder={t('signupPage.confirmPasswordPlaceholder')}
            id="confirm-password"
            value={confirmPassword}
            isError={outlinePassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="flex pt-2 min-h-16 pb-10">
            <AuthError error={error} />
          </div>
          <AuthSubmitButton showLoading={signupLoading} onClick={signupClick}>
            {t('signupPage.signup')}
          </AuthSubmitButton>
          <div className="h-5" />
          <div className="font-inter text-xs font-medium flex items-center justify-center">
            <p className="text-qura-neutral-balanced pr-1">Already have an account?</p>
            <button className="hover:underline" onClick={loginClick}>
              Log in.
            </button>
          </div>
          <div className="h-7" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
    </AuthBackground>
  );
};
