/* eslint-disable react-hooks/exhaustive-deps */
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFilterAfterYear, setFilterBeforeYear } from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/useSearchStore';
import { filter_end_date_select, filter_start_date_select } from '@/services/posthog/events';
import { useFilterDates } from '@/services/api/actions/getFilterDates';
import { Icons } from '@/assets';

const AVAILABLE_DATES = {
  afterDate: [1900, 1, 1],
  beforeDate: [new Date().getFullYear(), 12, 31],
};

export const YearFilter = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const filterTags = useSearchStore((state) => state.currentParams.filter.tagIds);
  const { data: fetchedAvailableDates } = useFilterDates(filterTags);
  const availableDates =
    filterTags.length > 0 ? (fetchedAvailableDates ?? AVAILABLE_DATES) : AVAILABLE_DATES;

  const currentFromDate = useSearchStore((state) => state.currentParams.filter.afterDate);
  const currentToDate = useSearchStore((state) => state.currentParams.filter.beforeDate);
  const isActive = currentFromDate !== null || currentToDate !== null;

  const [afterYearInput, setBeforeYearInput] = useState('');
  const [beforeYearInput, setAfterYearInput] = useState('');

  useEffect(() => {
    if (afterYearInput.length == 4) {
      const afterYear = parseInt(afterYearInput);
      if (isNaN(afterYear)) {
        return;
      }

      if (afterYear > availableDates.beforeDate[0]) {
        setBeforeYearInput(availableDates.beforeDate[0].toString());
        return;
      }

      if (afterYear < parseInt(beforeYearInput)) {
        setBeforeYearInput(beforeYearInput);
        return;
      }

      posthog.capture(filter_end_date_select, { year: afterYear });
      setFilterBeforeYear(afterYear);
    }
  }, [beforeYearInput]);

  useEffect(() => {
    if (beforeYearInput.length == 4) {
      const beforeYear = parseInt(beforeYearInput);
      if (isNaN(beforeYear)) {
        return;
      }

      if (beforeYear < availableDates.afterDate[0]) {
        setAfterYearInput(availableDates.afterDate[0].toString());
        return;
      }

      if (beforeYear > parseInt(afterYearInput)) {
        setAfterYearInput(afterYearInput);
        return;
      }

      posthog.capture(filter_start_date_select, { year: beforeYear });
      setFilterAfterYear(beforeYear);
    }
  }, [beforeYearInput]);

  // when the available dates change, update the input fields to the new range
  useEffect(() => {
    if (currentFromDate !== null && availableDates.afterDate[0] > currentFromDate[0]) {
      setAfterYearInput(availableDates.afterDate[0].toString());
      setFilterAfterYear(availableDates.afterDate[0]);
    }

    if (currentToDate !== null && availableDates.beforeDate[0] < currentToDate[0]) {
      setBeforeYearInput(availableDates.beforeDate[0].toString());
      setFilterBeforeYear(availableDates.beforeDate[0]);
    }
  }, [availableDates]);

  const onClick = () => {
    if (isActive) {
      setFilterAfterYear(null);
      setFilterBeforeYear(null);
    } else {
      setAfterYearInput(availableDates.afterDate[0].toString());
      setBeforeYearInput(availableDates.beforeDate[0].toString());
      setFilterAfterYear(availableDates.afterDate[0]);
      setFilterBeforeYear(availableDates.beforeDate[0]);
    }
  };

  return (
    <div className="flex items-center px-5 h-8 text-xs">
      <button
        onClick={onClick}
        className={`flex items-center p-2 gap-2 hover:bg-qura-neutral-ghost rounded h-8 ${!isActive && 'w-full'}`}>
        <div className=" w-2 h-2 rounded-full border border-qura-neutral-jet flex justify-center items-center">
          {isActive && <div className=" w-1 h-1 rounded-full bg-qura-neutral-jet" />}
        </div>
        {!isActive && <p className="text-xs">{t('filters.filterByYear')}</p>}
      </button>
      {isActive && (
        <div className="flex justify-between w-full pr-[34px]">
          <div className="flex gap-2 items-center ">
            <label htmlFor="fromYear" className="text-gray-600 w-fit">
              {t('filters.from')}
            </label>
            <input
              className="text-gray-600 w-10  rounded-smaller text-center bg-qura-neutral-ghost border focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-neutral-silver outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="number"
              id="fromYear"
              value={beforeYearInput}
              onChange={(e) => setAfterYearInput(e.target.value)}
            />
            <label htmlFor="toYear" className="text-gray-600">
              {t('filters.to')}
            </label>
            <input
              className="text-gray-600 w-10  rounded-smaller text-center border bg-qura-neutral-ghost focus:text-black focus:border-slate-200 focus:bg-transparent border-qura-neutral-silver outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="number"
              id="toYear"
              value={afterYearInput}
              onChange={(e) => setBeforeYearInput(e.target.value)}
            />
          </div>
          <button
            onClick={onClick}
            className="flex items-center p-2 gap-2 hover:bg-qura-neutral-ghost rounded h-8 ml-2">
            <Icons.Close className="w-2" />
          </button>
        </div>
      )}
    </div>
  );
};
