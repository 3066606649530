import isEqual from 'lodash.isequal';
import { setSearchError } from './searchError';
import { useSearchStore } from '../useSearchStore';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { initSearchWebSocket } from '@/services/api/actions/search';
import { SearchResult } from '@/types/api';
import devlog from '@/utils/devlog';

const onSearchId = (searchId: string, ws: WebSocket) => {
  devlog('WS SEARCH ID', searchId);
  useSearchStore.setState(
    (state) => {
      if (state.searches[searchId]) {
        throw new Error('search id already exists');
      }
      state.searches[searchId] = {
        ws,
        results: null,
        submittedParams: state.currentParams,
      };
      state.error = null;
      state.currentSearchId = searchId;
    },
    false,
    'searches/ws-connected',
  );
};

const onResult = (searchId: string, results: SearchResult) => {
  useSearchStore.setState(
    (state) => {
      state.searches[searchId].results = results;
      if (results.filterSelection && searchId === state.currentSearchId) {
        if (!isEqual(state.searches[searchId].submittedParams.filter, results.filterSelection)) {
          state.searches[searchId].submittedParams.filter = results.filterSelection;
          state.currentParams.filter = results.filterSelection;
        }
      }
    },
    false,
    'searches/set-results',
  );
};

const onShutdown = (searchId: string | null) => {
  devlog('WS SHUTDOWN', searchId);
  if (!searchId) return;
  useSearchStore.setState(
    (state) => {
      state.searches[searchId].ws = null;
    },
    false,
    'searches/search-shutdown',
  );
};

const onError = (err: AppError) => {
  devlog('WS ERROR', err);
  setSearchError(err);
};

export const createNewSearch = async () => {
  const lastSearchId = useSearchStore.getState().currentSearchId;
  const lastWs = lastSearchId ? useSearchStore.getState().searches[lastSearchId]?.ws : null;

  if (lastWs) {
    lastWs.close();
    console.log('CLOSED WEB SOCKET');
  }

  return await initSearchWebSocket({ onSearchId, onResult, onError, onShutdown });
};
