import axios from 'axios';
import posthog from 'posthog-js';
import { RESOURCE_API_URL } from '../constants';
import { pruneBigData } from '../utils';
import { getLogMetadata } from '@/utils/getLogMetadata';
import { catchAppError } from '@/utils/createAppError';
import devlog from '@/utils/devlog';

export async function sendErrorLog(error: unknown) {
  const appError = catchAppError(error);

  const data = {
    ...getLogMetadata(),
    error_message: appError.message,
    error_type: appError.type,
    error,
  };

  posthog.capture('app_error', {
    error_type: appError.type,
    message: appError.message,
  });

  devlog('LOGGING ERROR', appError);

  await axios
    .post(`${RESOURCE_API_URL}/log/error`, {
      message: appError.message,
      data: pruneBigData(data, ['pdf']),
    })
    .catch((error) => {
      console.error('Failed to send error log', error);
    });
}

export async function catchAndLogError<T>(func: () => Promise<T>) {
  try {
    return await func();
  } catch (error) {
    sendErrorLog(error);
    throw error;
  }
}
