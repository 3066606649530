import { useRef } from 'react';
import { Icons } from '@/assets';

type Props = {
  isLoading: boolean;
};

export const LoadingCover = ({ isLoading, children }: React.PropsWithChildren<Props>) => {
  const isLoadingRef = useRef(isLoading);
  const wasLoading = isLoadingRef.current && !isLoading;

  return (
    <>
      <div
        className={`z-50 w-screen h-screen flex justify-center items-center bg-white fixed inset-0 
      ${isLoading ? 'pointer-events-auto' : 'pointer-events-none'}
      ${
        isLoading
          ? wasLoading
            ? 'opacity-100'
            : 'animate-fade-in'
          : wasLoading
            ? 'animate-fade-out'
            : 'opacity-0'
      }`}>
        <div className="overflow-hidden">
          <Icons.LogoText />
        </div>
      </div>
      {!isLoading && children}
    </>
  );
};
