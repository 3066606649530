import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { I18nextProvider } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import useAppropriateFavicon from './hooks/useAppropriateFavicon';
import { AuthProvider } from './services/firebase/context/AuthContext';
import i18n from './services/i18next/i18nextConfig';
import { registerPosthog } from './services/posthog/registerPosthog';
import { queryClient } from './services/react-query/queryClient';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

const posthogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  loaded: registerPosthog,
};

function App() {
  useAppropriateFavicon();
  return (
    <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </I18nextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </PostHogProvider>
  );
}

export default App;
