import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthBackground } from '../../components/AuthBackground';
import { AuthContainerCard } from '../../components/AuthContainerCard';
import { AuthDescription } from '../../components/AuthDescription';
import { AuthError } from '../../components/AuthError';
import { AuthFooter } from '../../components/AuthFooter';
import { AuthInputCard } from '../../components/AuthInputCard';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthSubmitButton } from '../../components/AuthSubmitButton';
import AuthTextInput from '../../components/AuthTextInput';
import { createAppError } from '@/utils/createAppError';
import { forgotPassword } from '@/services/firebase/functions/forgotPassword';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { Icons } from '@/assets';
import { sendErrorLog } from '@/services/api/actions/sendErrorLog';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AppError | null>(null);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const recoverPasswordClick = () => {
    setIsLoading(true);
    setError(null);

    forgotPassword(email)
      .then(() => {
        setSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        sendErrorLog(error);
        setError(createAppError('message', 'Failed to send reset password email.'));
        setIsLoading(false);
      });
    setSuccess(true);
  };

  const loginClick = () => {
    navigate('/auth');
  };

  return (
    <AuthBackground>
      <AuthContainerCard>
        <AuthInputCard>
          <div className="h-12" />
          <AuthLogo />
          <AuthDescription text={t('forgotPasswordPage.description')} />
          <div className="h-2" />
          <AuthTextInput
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            id="email"
            value={email}
            placeholder={t('common.emailPlaceholder')}
            Icon={Icons.Mail}
            label={t('common.email')}
            isError={!!error}
          />
          <div className="h-2" />
          <AuthError error={error} />
          <button
            onClick={loginClick}
            className="self-start text-xs font-medium text-black hover:underline li">
            <Icons.Arrow className="transform rotate-90 h-[5px] mb-[2px] inline-block" />
            {t('common.login')}
          </button>
          <div className="h-16" />
          {success ? (
            <p className="font-inter text-xs tracking-wide antialiased text-black font-semibold text-center px-6 py-3 rounded-smaller border border-dark-border">
              {t('forgotPasswordPage.emailSentSuccess')}
            </p>
          ) : (
            <AuthSubmitButton showLoading={isLoading} onClick={recoverPasswordClick}>
              {t('forgotPasswordPage.recoverPassword')}
            </AuthSubmitButton>
          )}
          <div className="h-12" />
        </AuthInputCard>
      </AuthContainerCard>
      <AuthFooter />
      <ToastContainer />
    </AuthBackground>
  );
};
