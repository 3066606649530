import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { catchAndLogError } from './sendErrorLog';
import { RESOURCE_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { availableDateRangeSchema } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

export async function getFilterDates(tag_ids: string[]) {
  const token = await getAuthToken();
  const response = await axios.post(
    `${RESOURCE_API_URL}/database/filters/dates`,
    {
      tag_ids,
    },
    standardAxiosOptions(token),
  );

  try {
    return availableDateRangeSchema.parse({
      after_date: response.data.start_date,
      before_date: response.data.end_date,
    });
  } catch (_) {
    return null;
  }
}

export const useFilterDates = (tag_ids: string[] = []) => {
  return useQuery({
    queryKey: ['filter_dates', ...tag_ids],
    queryFn: () => catchAndLogError(() => getFilterDates(tag_ids)),
  });
};
