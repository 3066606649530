import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from './getCurrentUser';
import { db } from '../firebaseConfig';
import { searchParamsSchema } from '@/services/api/apiValidation';
import { catchAndLogError } from '@/services/api/actions/sendErrorLog';

export async function getRecentSearches(maxSearches: number) {
  const user = getCurrentUser();

  if (!user) {
    throw new Error('No user found');
  }

  const searchesRef = collection(db, 'searches');
  const q = query(
    searchesRef,
    where('user_id', '==', user.uid),
    orderBy('created_at', 'desc'),
    limit(maxSearches),
  );
  const searchesSnapshot = await getDocs(q);

  if (searchesSnapshot.empty) {
    return [];
  }

  const seraches = [];

  for (const searchDoc of searchesSnapshot.docs) {
    const actionsRef = collection(searchDoc.ref, 'actions');
    const lastActionQuery = query(
      actionsRef,
      where('content_type', '==', 'search'),
      orderBy('submitted_at', 'desc'),
      limit(1),
    );
    const lastActionSnapshot = await getDocs(lastActionQuery);

    if (!lastActionSnapshot.empty) {
      const action = lastActionSnapshot.docs[0].data();

      const search = searchParamsSchema.parse(action['content']);
      seraches.push(search);
    } else {
      throw new Error('No actions found for search');
    }
  }

  return seraches;
}

export const useRecentSearches = (maxSearches: number) => {
  return useQuery({
    queryKey: ['recent_searches', maxSearches],
    queryFn: () => catchAndLogError(() => getRecentSearches(maxSearches)),
  });
};
