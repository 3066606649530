import { useSearchStore } from '../../state/useSearchStore';

export const FilterBadge = () => {
  const tagIds = useSearchStore((state) => state.currentParams.filter.tagIds);

  if (!tagIds.length) return null;

  return (
    <div className="absolute right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-neutral-jet text-white font-extrabold">
      {tagIds.length}
    </div>
  );
};
