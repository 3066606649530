import isEqual from 'lodash.isequal';
import { useSearchStore } from '../useSearchStore';

export const useFilterHasChanged = (searchId: string) => {
  const hasChanged = useSearchStore(
    (state) =>
      !isEqual(state.currentParams.filter, state.searches[searchId]?.submittedParams.filter),
  );
  return hasChanged;
};
