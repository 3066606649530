import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { createAppError } from '@/utils/createAppError';

export async function logout() {
  try {
    await signOut(auth);
  } catch (error: any) {
    const message = error.message;
    throw createAppError(
      'unknown',
      'Failed to log out with Firebase. Firebase Error Message: ' + message,
    );
  }
}
